import { generateTitleCardId } from '@dmp/qqms/survey-generators';
import { useRecoilCallback } from 'recoil';
import { insertNewCard } from '../../utils/insertNewCard';
import { surveyState } from '../survey/survey-atoms';
import type { SurveyState } from '../survey/survey-types';
import { cardItemState, defaultTitle, surveyCardsSelector } from './card-atoms';
import type { CardItemState, TitleCardItemState } from './card-types';

export const useCardAddTitle = (cardId?: CardItemState['id']) => {
    const addTitleCard = useRecoilCallback(
        ({ set, snapshot }) =>
            async () => {
                const survey = await snapshot.getPromise(surveyState);
                const cardsInSurvey = await snapshot.getPromise(
                    surveyCardsSelector
                );

                const { newSurvey, newCards } = addTitleCardUtil(
                    survey,
                    cardsInSurvey
                )(cardId);

                // update all cards, & create new card atom
                newCards.forEach((c) => set(cardItemState(c.id), c));

                // add new card id into survey
                set(surveyState, newSurvey);
            },
        [cardId]
    );

    return {
        addTitleCard,
    };
};

/**
 * Create new card and get modified atoms
 */
export const addTitleCardUtil =
    (survey: SurveyState, cardsInSurvey: CardItemState[]) =>
    (cardId?: CardItemState['id']) => {
        const newTitle: TitleCardItemState = {
            ...defaultTitle,
            id: generateTitleCardId(),
        };

        // Insert new card and reconnects `resultCard` in the collection
        const { newSurvey, newCards } = insertNewCard(survey, cardsInSurvey)(
            newTitle,
            cardId
        );

        return {
            newSurvey,
            newCards,
        };
    };
