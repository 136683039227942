import type { Survey, SurveyConfig } from '@dmp/qqms/types';

export const defaultSurveyConfig: SurveyConfig = {
    cards: [],
    traxexLabel: '',
    aboutCopy: '',
    liveProofUrl: '',
};

export const defaultSurvey: Survey = {
    ...defaultSurveyConfig,
    id: '',
    shortId: '',
    qqid: '',
};
