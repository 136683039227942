import type { Card, Question } from '@dmp/qqms/types';
import { useEffect, useRef } from 'react';
import { classes } from '../utils';
import { PollResultCardComponent } from './poll-result-card-component';
import { QuestionComponent } from './question-component';
import { TitleCardComponent } from './title-card-component';

export interface CardComponentProps {
    card: Card;
    animState:
        | 'idle'
        | 'added'
        | 'added-backward'
        | 'removed'
        | 'removed-backward';
}

export function CardComponent(props: CardComponentProps) {
    const cardRef = useRef<HTMLDivElement>(null);

    /**
     * If Card was just added, remove its class
     * in order to immediately trigger animation.
     */
    useEffect(() => {
        const card$ = cardRef.current;
        const wasAdded = ['added', 'added-backward'].includes(props.animState);

        if (!card$ || !wasAdded) {
            return;
        }

        if (Array.from(card$.classList).includes(props.animState)) {
            setTimeout(() => card$.classList.remove(props.animState), 0);
        }
    }, [props.animState]);

    const className = classes(
        'card',
        props.animState,
        classFromCard(props.card)
    );

    return (
        <div className={className} ref={cardRef}>
            <CardSwitch {...props} />
        </div>
    );
}

interface CardSwitchProps {
    card: Card;
}

function CardSwitch({ card }: CardSwitchProps) {
    switch (card.type) {
        case 'forkedQuestion':
        case 'linearQuestion':
            return <QuestionComponent question={card} />;

        case 'thankYouCard':
        case 'titleCard':
            return <TitleCardComponent card={card} />;

        case 'pollResultCard':
            return <PollResultCardComponent card={card} />;

        case 'dynamicCoreQuestion':
            throw new Error(
                'A dynamic core question card should be replaced before rendering.'
            );
    }
}

function classFromCard(card: Card): string | undefined {
    switch (card.type) {
        case 'linearQuestion':
        case 'forkedQuestion':
            return classFromQuestion(card);

        case 'pollResultCard':
            return 'pr-container';

        case 'titleCard':
        case 'thankYouCard':
            return 'title-block';

        default:
            return undefined;
    }
}

function classFromQuestion(question: Question) {
    const answerCount = question.answers.length;

    switch (question.formType) {
        case 'select':
            return classes('question', 'question-select');

        case 'radio':
            return classes('question', `question-${answerCount}`);

        case 'multi-select':
            return classes('question', `question-${answerCount + 1}`);
    }
}
