import { Box, Text, useColorMode, VStack } from '@chakra-ui/react';
import type { Card } from '@dmp/qqms/types';
import * as React from 'react';
import { usaTodayBlue } from '../../../theme/foundations/colors';
import type { VisualCardType } from '../../../utils/getVisualCardType';
import { IconMultiSelect } from '../../card-type-icons/icon-multi-select';
import { IconTitle } from '../../card-type-icons/icon-title';
import { IconButton } from './../../card-type-icons/icon-button';
import { IconCoreQuestion } from './../../card-type-icons/icon-core-question';
import { IconDropDown } from './../../card-type-icons/icon-dropdown';
import { IconThankYou } from './../../card-type-icons/icon-thankyou';
import { useCardVisualItem } from './use-card-visual-item';

interface CardVisualItemComProps {
    cardId: Card['id'];
    name: string;
    visualType: VisualCardType;
}

const CardVisualItemCom: React.FC<CardVisualItemComProps> = ({
    cardId,
    visualType,
    name,
}) => {
    const { isActive, isVisible, handleSelect } = useCardVisualItem(
        cardId,
        visualType
    );

    const { colorMode } = useColorMode();

    if (!isVisible) {
        return null;
    }

    const isDark = colorMode === 'dark';
    const blue = usaTodayBlue(colorMode);
    const bg = isActive ? blue : 'transparent';
    const color = isActive ? (isDark ? 'gray.700' : 'white') : 'gray.500';
    const hover = isActive ? {} : { color: blue };

    return (
        <VStack
            as="button"
            px="2"
            py="3"
            spacing="1"
            cursor={isActive ? 'default' : 'pointer'}
            color={color}
            bg={bg}
            _hover={hover}
            onClick={handleSelect}
        >
            <Box
                w="48px"
                h="36px"
                rounded="xl"
                border="2px"
                borderColor="currentColor"
            >
                {visualType === 'radio' && <IconButton w="100%" h="100%" />}
                {visualType === 'select' && <IconDropDown w="100%" h="100%" />}
                {visualType === 'multi-select' && (
                    <IconMultiSelect w="100%" h="100%" />
                )}
                {visualType === 'titleCard' && <IconTitle w="100%" h="100%" />}
                {visualType === 'thankYouCard' && (
                    <IconThankYou w="100%" h="100%" />
                )}
                {visualType === 'dynamicCoreQuestion' && (
                    <IconCoreQuestion w="100%" h="100%" />
                )}
            </Box>
            <Text fontSize="xs">{name}</Text>
        </VStack>
    );
};

export const CardVisualItem = React.memo(CardVisualItemCom);
export default CardVisualItem;
