import type { Card, Survey } from '@dmp/qqms/types';
import {
    isForkedQuestion,
    isQuestion,
    isThankYouCard,
    isPollResultCard,
    selectNextCardId,
} from '@dmp/qqms/survey-utils';
import { getCardById } from './getCardById';

export interface TreeInfo {
    name: string;
    children: TreeInfo[];
    data?: Card;
}

const cardName = (card: Card) =>
    isQuestion(card) || isThankYouCard(card) ? card.body : card.type;

/**
 * Convert cards to tree structure
 */
export const convertCardsToTree = (
    startingCard: Card | undefined,
    allCards: Survey['cards'],
    ancestorCardIds: Array<Card['id']> = []
): TreeInfo => {
    // ancestors id is repeated => Looped
    if (!startingCard || ancestorCardIds.includes(startingCard.id)) {
        return { name: 'Error: Looped Question', children: [] };
    }

    const ancestorIds = ancestorCardIds.concat(startingCard.id);
    let children: TreeInfo[] = [];

    // is Forked Question
    // - connect via answer's `resultCard` and fallback to the next index card
    if (isForkedQuestion(startingCard)) {
        children = startingCard.answers.map((a) => {
            const connectId = selectNextCardId({
                card: startingCard,
                allCards,
                answerId: a.id,
            });
            return convertCardsToTree(
                getCardById(connectId, allCards),
                allCards,
                ancestorIds
            );
        });
        // is others, NON-ThankYou Card
        // - connect via question's `resultCard` and fallback to the next index card
    } else if (
        !isThankYouCard(startingCard) &&
        !isPollResultCard(startingCard)
    ) {
        const connectId = selectNextCardId({
            card: startingCard,
            allCards,
            answerId: '', // non-relevant
        });
        children = [
            convertCardsToTree(
                getCardById(connectId, allCards),
                allCards,
                ancestorIds
            ),
        ];
    }

    return {
        name: cardName(startingCard),
        children,
        data: startingCard,
    };
};

/**
 * Extract all connected cards based on the survey treeInfo
 */
export const extractConnectedCards = (
    acc: string[],
    treeInfo?: TreeInfo
): string[] => {
    if (!treeInfo) {
        return acc;
    }

    const newAcc = treeInfo.data?.id ? acc.concat(treeInfo.data.id) : acc;

    return treeInfo.children.reduce(
        (innerAcc, childTreeInfo) =>
            extractConnectedCards(innerAcc, childTreeInfo),
        newAcc
    );
};
