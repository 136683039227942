import type { MouseEventHandler } from 'react';

interface ButtonComponentProps {
    className?: string;
    disabled?: boolean;
    label: string;
    onClick: MouseEventHandler;
    id?: string;
    isMultiSelect?: boolean;
}

export function ButtonComponent({
    className,
    disabled,
    label,
    onClick: onAnswerButton,
    id,
    isMultiSelect = false,
}: ButtonComponentProps) {
    const onClick: MouseEventHandler<HTMLButtonElement> = (e) => {
        if (disabled) {
            return;
        }

        if (!isMultiSelect) {
            (e.currentTarget as HTMLButtonElement).classList.add(
                'button-active'
            );
        }

        onAnswerButton(e);
    };

    return (
        <button
            className={['answer', className].filter(Boolean).join(' ')}
            disabled={disabled}
            id={id}
            onClick={onClick}
        >
            {label}
        </button>
    );
}
