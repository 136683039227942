import {
    Box,
    Button,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
} from '@chakra-ui/react';
import * as React from 'react';
import {
    VscAdd as IconAdd,
    VscChevronDown as IconSelect,
} from 'react-icons/vsc';

import type { CardItemState } from '../../../state/card/card-types';
import { useCardAddDynamicCoreQuestion } from '../../../state/card/use-card-add-dynamic-core-question';
import { useCardAddPollResult } from '../../../state/card/use-card-add-poll-result';
import { useCardAddQuestion } from '../../../state/card/use-card-add-question';
import { useCardAddThankYou } from '../../../state/card/use-card-add-thankyou';
import { useCardAddTitle } from '../../../state/card/use-card-add-title';
import { IconCoreQuestion } from '../../card-type-icons/icon-core-question';
import { IconLeadInQuestion } from '../../card-type-icons/icon-lead-in-question';
import { IconPollResult } from '../../card-type-icons/icon-poll-result';
import { IconThankYou } from '../../card-type-icons/icon-thankyou';
import { CardAddItem } from './card-add-item';
import { IconTitle } from '../../card-type-icons/icon-title';

interface CardAddComProps {
    cardId: CardItemState['id'];
}

const CardAddCom = ({ cardId }: CardAddComProps) => {
    const { addQuestionCard } = useCardAddQuestion(cardId);
    const { addTitleCard } = useCardAddTitle(cardId);
    const { addThankYouCard } = useCardAddThankYou(cardId);
    const { addPollResultCard } = useCardAddPollResult(cardId);
    const { addCoreQuestion } = useCardAddDynamicCoreQuestion(cardId);

    return (
        <Box py="10">
            <Menu>
                <MenuButton
                    as={Button}
                    leftIcon={<IconAdd />}
                    rightIcon={<IconSelect />}
                >
                    new card
                </MenuButton>
                <MenuList>
                    <MenuItem onClick={addQuestionCard}>
                        <CardAddItem title="Lead-In Question">
                            <IconLeadInQuestion w="100%" h="100%" />
                        </CardAddItem>
                    </MenuItem>

                    <MenuItem onClick={addCoreQuestion}>
                        <CardAddItem title="Core Question">
                            <IconCoreQuestion w="100%" h="100%" />
                        </CardAddItem>
                    </MenuItem>

                    <MenuDivider />

                    <MenuItem onClick={addTitleCard}>
                        <CardAddItem title="Title Card">
                            <IconTitle w="100%" h="100%" />
                        </CardAddItem>
                    </MenuItem>

                    <MenuItem onClick={addThankYouCard}>
                        <CardAddItem title="ThankYou Note">
                            <IconThankYou w="100%" h="100%" />
                        </CardAddItem>
                    </MenuItem>

                    <MenuItem onClick={addPollResultCard}>
                        <CardAddItem title="Poll Result">
                            <IconPollResult w="100%" h="100%" />
                        </CardAddItem>
                    </MenuItem>
                </MenuList>
            </Menu>
        </Box>
    );
};

export const CardAdd = React.memo(CardAddCom);
