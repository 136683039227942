import { memo } from 'react';
import type { IconProps } from '@chakra-ui/react';
import { Icon } from '@chakra-ui/react';

const IconTitleCom = (props: IconProps) => {
    return (
        <Icon viewBox="0 0 80 60" {...props}>
            <path
                fill="currentColor"
                d="M37.1,47.7V31.5h-0.4c-4.1,0-9.7-3.2-9.8-9.8c-0.1-5.4,2.9-11.5,14.2-11.5c2.7,0,4.8,0.2,6.1,0.4v37.1h-3.2V13.3h-3.7v34.4 H37.1z"
            />
        </Icon>
    );
};

export const IconTitle = memo(IconTitleCom);
