import { useSurveyState } from '../state/use-survey-state';

export function SponsorComponent() {
    const {
        survey: { sponsorInfo },
    } = useSurveyState();

    if (!sponsorInfo) {
        return null;
    }

    return (
        <div id="sponsor">
            {sponsorInfo.copy && (
                <div id="sponsor-copy">{sponsorInfo.copy}</div>
            )}
            <div
                id="sponsor-logo"
                style={{
                    backgroundImage: `url(${sponsorInfo.logo})`,
                }}
            ></div>
        </div>
    );
}
