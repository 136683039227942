import type {
    AnswersSelectedEvent,
    CardSkippedEvent,
} from '@dmp/qqms-renderer';
import {
    useDispatch,
    useEventListener,
    useSurveyState,
} from '@dmp/qqms-renderer';
import type { Card } from '@dmp/qqms/types';
import { useCallback, useState } from 'react';

export function useSurveyPreviewControls() {
    const { survey } = useSurveyState();
    const dispatch = useDispatch();

    // record order of cards answered
    const [cardsAnswered, setCardsAnswered] = useState<Array<Card['id']>>([]);

    const recordEvent = useCallback(
        (event: AnswersSelectedEvent | CardSkippedEvent) => {
            const card =
                'card' in event.detail
                    ? event.detail.card
                    : event.detail.question;

            setCardsAnswered((currentCards) => currentCards.concat(card.id));
        },
        []
    );

    useEventListener('cardSkipped', recordEvent);
    useEventListener('answersSelected', recordEvent);

    const goToCard = (cardId: Card['id']) => {
        if (!cardId) {
            return;
        }

        dispatch('cardSelected', { cardId });

        // update record, remove the selected card and ones after it.
        const cardIndex = cardsAnswered.findIndex((id) => id === cardId);
        setCardsAnswered([...cardsAnswered.slice(0, cardIndex)]);
    };

    const atStart = cardsAnswered.length === 0;
    const goBack = () => goToCard(cardsAnswered[cardsAnswered.length - 1]);
    const startOver = () => !atStart && survey && goToCard(survey.cards[0].id);

    return { atStart, goBack, startOver };
}
