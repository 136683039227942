import { HStack, Icon } from '@chakra-ui/react';
import type { Card } from '@dmp/qqms/types';
import * as React from 'react';
import { BsArrowReturnRight as IconConnect } from 'react-icons/bs';
import { useRecoilValue } from 'recoil';
import { cardTypeState } from '../../../state/card/card-atoms';
import { CardConnectionForked } from './card-connection-forked';
import { CardConnectionNonForked } from './card-connection-non-forked';
import { QuestionTypeSelect } from './question-type-select';

interface CardConnectionProps {
    cardId: Card['id'];
}

const CardConnectionCom = ({ cardId }: CardConnectionProps) => {
    const cardType = useRecoilValue(cardTypeState(cardId));

    switch (cardType) {
        case 'titleCard':
        case 'thankYouCard':
        case 'pollResultCard':
            return null;

        case 'linearQuestion':
        case 'forkedQuestion':
        case 'dynamicCoreQuestion': {
            return (
                <HStack w="100%" align="start" spacing="3">
                    <HStack>
                        <Icon as={IconConnect} opacity="0.5" />

                        {/* Wrapping <div /> required, because Popper won't allow margin on <QuestionTypeSelect /> */}
                        {cardType !== 'dynamicCoreQuestion' && (
                            <div>
                                <QuestionTypeSelect cardId={cardId} />
                            </div>
                        )}
                    </HStack>

                    {cardType === 'forkedQuestion' ? (
                        <CardConnectionForked cardId={cardId} />
                    ) : (
                        <CardConnectionNonForked cardId={cardId} />
                    )}
                </HStack>
            );
        }

        default: {
            cardType satisfies never;
            throw new Error(`Unsupported card type: ${cardType}`);
        }
    }
};

export const CardConnection = React.memo(CardConnectionCom);
