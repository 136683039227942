import type { Card } from '@dmp/qqms/types';
import { useRecoilValue } from 'recoil';
import * as React from 'react';

import { cardBodyState, cardTypeState } from '../../state/card/card-atoms';
import { truncateString } from '@dmp/shared/client-utils';

interface OptionByCardIdProps {
    cardId: Card['id'];
    prefix?: string;
}
const OptionByCardIdCom: React.FC<OptionByCardIdProps> = ({
    cardId,
    prefix = '',
}) => {
    const cardType = useRecoilValue(cardTypeState(cardId));
    const cardBody = useRecoilValue(cardBodyState(cardId));

    const title =
        cardType === 'dynamicCoreQuestion'
            ? 'Dynamic Core Question [*]'
            : `${cardBody} [${cardType}]`;

    return (
        <option value={cardId}>
            {prefix} {truncateString(title, 100)}
        </option>
    );
};

export const OptionByCardId = React.memo(OptionByCardIdCom);
