import type { Answer } from '@dmp/qqms/types';
import { generateSimpleId } from '@dmp/shared/helpers';

export function generateDynamicCoreQuestionId() {
    return generateSimpleId('c-dyna');
}

export function generateCoreQuestionId() {
    return generateSimpleId('c-core');
}

export function generateAnswerId(type: Answer['type']) {
    const prefix = answerTypeToPrefix(type);

    return generateSimpleId(prefix);
}

export function generateSurveyId() {
    return generateSimpleId('s-surv');
}

export function generateQuestionId() {
    return generateSimpleId('c-ques');
}

export function generateTitleCardId() {
    return generateSimpleId('c-titl');
}

export function generateThankYouCardId() {
    return generateSimpleId('c-than');
}

export function generatePollResultCardId() {
    return generateSimpleId('c-poll');
}

function answerTypeToPrefix(type: Answer['type']) {
    switch (type) {
        case 'forkedAnswer':
            return 'a-fork';
        case 'linearAnswer':
            return 'a-line';
        default:
            throw new Error('Invalid Answer type');
    }
}
