import type { Question } from '@dmp/qqms/types';
import { ButtonGroupComponent } from './button-group-component';
import { ButtonGroupMultiSelectComponent } from './button-group-multi-select-component';
import { DropDownComponent } from './dropdown-component';
import { PollResultNoteComponent } from './poll-result-note-component';
import { isLinearQuestion } from '@dmp/qqms/survey-utils';
import { useDispatch } from '../events/hooks';

interface QuestionComponentProps {
    question: Question;
}

export function QuestionComponent(props: QuestionComponentProps) {
    const { question } = props;

    return (
        <>
            <PollResultNoteComponent question={question} />

            <p className="question-body">{question.body}</p>

            <QuestionSwitch {...props} />

            <SkipQuestion question={question} />
        </>
    );
}

function QuestionSwitch(props: QuestionComponentProps) {
    switch (props.question.formType) {
        case 'select': {
            return <DropDownComponent {...props} />;
        }

        case 'radio': {
            return <ButtonGroupComponent {...props} />;
        }

        case 'multi-select': {
            return <ButtonGroupMultiSelectComponent {...props} />;
        }
    }
}

function SkipQuestion({ question }: QuestionComponentProps) {
    const dispatch = useDispatch();

    if (!isLinearQuestion(question) || !question.isSkippable) {
        return null;
    }

    return (
        <button
            className="skip-question"
            onClick={() => {
                dispatch('cardSkipped', { card: question });
            }}
        >
            Skip Question →
        </button>
    );
}
