import {
    FormControl,
    FormErrorMessage,
    FormLabel,
    Textarea,
    useColorMode,
} from '@chakra-ui/react';
import { validURL } from '@dmp/shared/helpers';
import { useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { surveyLiveProofUrlState } from '../../../state/survey/survey-atoms';
import { trackingFieldStyles } from '../../../theme/common-styles/tracking-field-styles';

export const SurveyLiveProofUrl = () => {
    const [liveProofUrl, setLiveProofUrl] = useRecoilState(
        surveyLiveProofUrlState
    );
    const { colorMode } = useColorMode();
    const { label, input } = trackingFieldStyles(colorMode);
    const isValid = useMemo(
        () => validURL(liveProofUrl ?? '') || !liveProofUrl,
        [liveProofUrl]
    );

    return (
        <FormControl id="about-this-survey" isInvalid={!isValid}>
            <FormLabel {...label}>Live Proof Base URL</FormLabel>
            <Textarea
                {...input}
                placeholder="https://www.indystar.com/story/money/personal-finance/2017/12/01/students-quit-college-can-finish/108167470"
                value={liveProofUrl}
                onChange={(e) => setLiveProofUrl(e.target.value)}
            />
            <FormErrorMessage>{isValid} Invalid URL</FormErrorMessage>
        </FormControl>
    );
};

export default SurveyLiveProofUrl;
