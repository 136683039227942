import { atom, DefaultValue, selector } from 'recoil';
import { compareAndSet } from '../../utils/compare-and-set';
import type { SurveyState } from './survey-types';

export const surveyIdState = atom<SurveyState['id']>({
    key: 'surveyIDState',
    default: '',
});

export const surveyShortIdState = atom<SurveyState['id']>({
    key: 'surveyShortIdState',
    default: '',
});

export const surveyQQIDState = atom<SurveyState['qqid']>({
    key: 'surveyQQIDState',
    default: '',
});

export const surveyNameState = atom<SurveyState['name']>({
    key: 'surveyNameState',
    default: '',
});

export const surveyCardIdsState = atom<SurveyState['cards']>({
    key: 'surveyCardIdsState',
    default: [],
});

export const surveyTraxexLabelState = atom<SurveyState['traxexLabel']>({
    key: 'surveyTraxexLabelState',
    default: '',
});

export const surveyAboutCopyState = atom<SurveyState['aboutCopy']>({
    key: 'surveyAboutCopyState',
    default: '',
});

export const surveySponsorInfoState = atom<SurveyState['sponsorInfo']>({
    key: 'surveySponsoredCopyState',
    default: undefined,
});

export const surveyLiveProofUrlState = atom<SurveyState['liveProofUrl']>({
    key: 'surveyLiveProofUrlState',
    default: undefined,
});

export const surveyState = selector<SurveyState>({
    key: 'surveyState',
    get: ({ get }) => {
        const id = get(surveyIdState);
        const shortId = get(surveyShortIdState);
        const qqid = get(surveyQQIDState);
        const name = get(surveyNameState);
        const cards = get(surveyCardIdsState);
        const traxexLabel = get(surveyTraxexLabelState);
        const aboutCopy = get(surveyAboutCopyState);
        const sponsorInfo = get(surveySponsorInfoState);
        const liveProofUrl = get(surveyLiveProofUrlState);

        return {
            id,
            shortId,
            qqid,
            cards,
            name,
            traxexLabel,
            aboutCopy,
            sponsorInfo,
            liveProofUrl,
        };
    },
    set: ({ get, set, reset }, newValue) => {
        if (newValue instanceof DefaultValue) {
            reset(surveyIdState);
            reset(surveyShortIdState);
            reset(surveyQQIDState);
            reset(surveyNameState);
            reset(surveyCardIdsState);
            reset(surveyTraxexLabelState);
            reset(surveyAboutCopyState);
            reset(surveySponsorInfoState);
            reset(surveyLiveProofUrlState);

            return;
        }

        const _set = compareAndSet(get, set);
        _set(surveyIdState, newValue.id);
        _set(surveyShortIdState, newValue.shortId);
        _set(surveyQQIDState, newValue.qqid);
        _set(surveyNameState, newValue.name);
        _set(surveyCardIdsState, newValue.cards);
        _set(surveyTraxexLabelState, newValue.traxexLabel);
        _set(surveyAboutCopyState, newValue.aboutCopy);
        _set(surveySponsorInfoState, newValue.sponsorInfo);
        _set(surveyLiveProofUrlState, newValue.liveProofUrl);
    },
});
