import { Heading, Input, useColorMode, VStack } from '@chakra-ui/react';
import type { Card } from '@dmp/qqms/types';
import { memo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { buttonCopyState, cardTypeState } from '../../../state/card/card-atoms';
import { trackingFieldStyles } from '../../../theme/common-styles/tracking-field-styles';
import CardBody from './fields/card-body';
import { CardTraxexLabel } from './fields/card-traxex-label';

interface CardAsTitleComProps {
    cardId: Card['id'];
}

const CardAsTitleCom = ({ cardId }: CardAsTitleComProps) => {
    const cardType = useRecoilValue(cardTypeState(cardId));
    const [buttonLabel, setButtonLabel] = useRecoilState(
        buttonCopyState(cardId)
    );

    const { colorMode } = useColorMode();
    const { input } = trackingFieldStyles(colorMode);

    if (cardType !== 'titleCard') {
        return null;
    }

    return (
        <VStack align="stretch" spacing="8">
            <VStack align="stretch">
                <Heading as="h2" fontSize="sm">
                    Title Card Message
                </Heading>
                <CardBody
                    cardId={cardId}
                    isRichTextEditor={true}
                    fontSize="xl"
                    size="lg"
                    rows={5}
                    placeholder="enter title card body"
                />
            </VStack>

            <VStack align="stretch">
                <Heading as="h2" fontSize="sm">
                    Button Label
                </Heading>
                <Input
                    name={`${cardId}-button-label`}
                    value={buttonLabel}
                    onChange={(e) => setButtonLabel(e.target.value)}
                    placeholder="enter button label"
                    {...input}
                />
            </VStack>

            <CardTraxexLabel
                label="Tracking Label: User viewed the Title Card"
                cardId={cardId}
            />
        </VStack>
    );
};

export const CardAsTitle = memo(CardAsTitleCom);
