import { selectNextCardId } from '@dmp/qqms/survey-utils';
import type { Card, Survey } from '@dmp/qqms/types';
import { useEffect, useState } from 'react';
import { useDispatch, useEventListener } from '../../events/hooks';

// ----------------------------------------------------------------
// Types
// ----------------------------------------------------------------

export type SurveyState = IdleState | TransitioningState;

interface BaseState {
    survey: Survey;
}

export interface TransitioningState extends BaseState {
    type: 'transitioning';
    currentCard: Card;
    nextCard: Card;
    direction: 'forwards' | 'backwards';
}

export interface IdleState extends BaseState {
    type: 'idle';
    currentCard: Card;
}

interface UseSurveyStateOptions {
    survey: Survey;

    // Should this Survey ignore user interactions?
    isNonInteractive?: boolean;
}

// ----------------------------------------------------------------
// Functions
// ----------------------------------------------------------------

/**
 * Create and manage Survey State.
 *
 * NOTE: This function should only be used by the <SurveyProvider />. All other
 * Components should access and manage state via non-internal hooks.
 */
export function useSurveyState_INTERNAL({
    isNonInteractive,
    survey,
}: UseSurveyStateOptions): SurveyState {
    const dispatch = useDispatch();

    // ----------------------------------------------------------------
    // State
    // ----------------------------------------------------------------

    const [state, setState] = useState<SurveyState>(() => ({
        type: 'idle',
        survey,
        currentCard: survey.cards[0],
    }));

    /**
     * Reset if cards change. This is mostly in previews in QQMS Client.
     */
    useEffect(() => {
        setState({
            type: 'idle',
            survey,
            currentCard: survey.cards[0],
        });
    }, [survey]);

    // ----------------------------------------------------------------
    // Event Handlers
    // ----------------------------------------------------------------

    /**
     * Report that a Card has been viewed, but only
     * for PollResultCard and ThankYouCard.
     */
    useEffect(() => {
        const card = state.currentCard;

        if (
            card.type === 'pollResultCard' ||
            card.type === 'thankYouCard' ||
            card.type === 'titleCard'
        ) {
            dispatch('cardEntered', { card });
        }
    }, [dispatch, state.currentCard]);

    useEventListener('titleCardInteracted', (e) => {
        if (isNonInteractive) {
            return;
        }

        const nextCardId = selectNextCardId({
            card: e.detail.card,
            allCards: survey.cards,
        });

        if (nextCardId === undefined) {
            throw new Error('Unable to find next Card');
        }

        dispatch('cardSelected', { cardId: nextCardId });
    });

    /**
     * Initiate process to move on to next Card.
     *
     * 1. Indicate that next Card should be added to DOM.
     * 2. After slight delay, assume old card was animated out
     *    and new card was animated in.
     * 3. Indicate that old Card should be removed from DOM.
     */
    useEventListener('cardSelected', (e) => {
        if (isNonInteractive) {
            return;
        }

        const cardId = e.detail.cardId;
        const card = survey.cards.find((c) => c.id === cardId);

        if (card === undefined) {
            throw new Error(`Card with ID ${cardId} not found`);
        }

        const direction =
            survey.cards.findIndex((c) => c.id === cardId) >
            survey.cards.findIndex((c) => c.id === state.currentCard.id)
                ? 'forwards'
                : 'backwards';

        setState({
            type: 'transitioning',
            survey,
            currentCard: state.currentCard,
            nextCard: card,
            direction,
        });

        setTimeout(
            () =>
                setState({
                    type: 'idle',
                    survey,
                    currentCard: card,
                }),
            // Matches `.card` CSS transition time
            250
        );
    });

    /**
     * Answer handler.
     *
     * 1. Report to external `onAnswer` callback.
     * 2. Record answered Questions and selected Answers.
     * 3. Move on to the next Card.
     */
    useEventListener('answersSelected', (e) => {
        if (isNonInteractive || state.type !== 'idle') {
            return;
        }

        const { answers, question } = e.detail;

        // find answer id to process the next card id
        const answerIdForNextCard = answers[0].id;

        const nextCardId = selectNextCardId({
            answerId: answerIdForNextCard,
            card: question,
            allCards: survey.cards,
        });

        if (nextCardId === undefined) {
            throw new Error('Unable to find next Card');
        }

        dispatch('cardSelected', { cardId: nextCardId });
    });

    /**
     * Handle when the user skips a Question. Move on to the next Card.
     */
    useEventListener('cardSkipped', (e) => {
        if (isNonInteractive || state.type !== 'idle') {
            return;
        }

        const nextCardId = selectNextCardId({
            card: e.detail.card,
            allCards: survey.cards,
        });

        if (nextCardId === undefined) {
            throw new Error('Unable to find next Card');
        }

        dispatch('cardSelected', { cardId: nextCardId });
    });

    return state;
}
