import type { IdMap } from '@dmp/qqms/survey-utils';
import {
    isDynamicCoreQuestion,
    replaceCardResultIds,
} from '@dmp/qqms/survey-utils';
import type {
    Card,
    DynamicCoreQuestion,
    LinearQuestion,
    Question,
    Survey,
    UnitSetup,
} from '@dmp/qqms/types';

/**
 * Example input and output:
 *
 * Input:
 * +---------+    +----------------------------+    +----------+
 * | Lead-in | -> | Dynamic Core Question      | -> | ThankYou |
 * +---------+    |  _____   _____   ________  |    +----------+
 *                | | Age | | HHI | | Gender | |
 *                |  ‾‾‾‾‾   ‾‾‾‾‾   ‾‾‾‾‾‾‾‾  |
 *                +----------------------------+
 *
 * Output (assuming they have already answered HHI):
 * +---------+    +-----+    +--------+    +----------+
 * | Lead-in | -> | Age | -> | Gender | -> | ThankYou |
 * +---------+    +-----+    +--------+    +----------+
 */

interface InjectCoreQuestionsParams {
    survey: Survey;
    userTraits: UnitSetup['userTraits'];
}

/**
 * Replace the Dynamic Core Question with any Core Questions that should be
 * presented to the User. The ids of questions that the user has answered
 * should be in their list of user traits.
 */
export const injectCoreQuestions = ({
    survey,
    userTraits,
}: InjectCoreQuestionsParams): Survey => {
    const userTraitList = userTraits.split(',');

    // Replace all Dynamic Core Questions with the
    // Core Questions that the User hasn't answered.
    const { cards, idMap } = replaceDynamicCoreQuestions(
        survey.cards,
        userTraitList
    );

    /**
     * If any cards previously pointed to a Dynamic Core Question, they should
     * be updated to point to the new Questions that were dynamically inserted.
     */
    const redirectedCards = cards.map(replaceCardResultIds(idMap));

    // Add the final Core Questions into the Survey
    return {
        ...survey,
        cards: redirectedCards,
    };
};

interface DynamicCoreQuestionReplacementMap {
    // If a Question previously pointed to a Dynamic Core Question, it will
    // need to point to the first Core Question that replaces it. `idMap` maps
    // the Dynamic Core Question IDs to the IDs of the Cards that replaced them.
    idMap: IdMap;

    // All Cards with Dynamic Core Questions replaced.
    cards: Card[];
}

/**
 * Replace all Dynamic Core Questions with their assigned
 * Core Questions that the User has not yet answered.
 */
const replaceDynamicCoreQuestions = (
    cards: Card[],
    userTraitList: string[]
): DynamicCoreQuestionReplacementMap => {
    const idMap: IdMap = {};
    const updatedCards: Card[] = [];

    for (const card of cards) {
        if (!isDynamicCoreQuestion(card)) {
            updatedCards.push(card);
            continue;
        }

        const replacement = replaceDynamicCoreQuestion(card, userTraitList);

        idMap[replacement.originalTargetId] = replacement.newTargetId;
        updatedCards.push(...replacement.cards);
    }

    return {
        cards: updatedCards,
        idMap,
    };
};

interface DynamicCoreQuestionReplacement {
    originalTargetId: Question['id'];
    newTargetId?: Question['id'];
    cards: LinearQuestion[];
}

/**
 * Replace a Dynamic Core Question with any of
 * its Core Questions that have not been answered.
 */
const replaceDynamicCoreQuestion = (
    dynamicCoreQuestion: DynamicCoreQuestion,
    userTraits: string[]
): DynamicCoreQuestionReplacement => {
    const cards: LinearQuestion[] = dynamicCoreQuestion.questions
        // Only include Core Questions the User hasn't already answered.
        .filter((coreQuestion) => {
            // allow to reask when `allowReask` is `true`
            if (dynamicCoreQuestion.allowReask) {
                return true;
            }

            // otherwise, only include questions that the user has not answered
            return !userTraits.includes(coreQuestion.id);
        })
        // If `maximizeResponses` is `true`, only include up to 3 questions.
        .filter((_coreQuestion, idx) => {
            return !dynamicCoreQuestion.maximizeResponses || idx < 3;
        })
        // Convert the Core Questions to Linear Questions and map their Result Cards.
        .map((coreQuestion, idx, filteredCoreQuestions) => ({
            ...coreQuestion,
            type: 'linearQuestion',
            isCoreQuestion: true,
            resultCard:
                filteredCoreQuestions[idx + 1]?.id ||
                dynamicCoreQuestion.resultCard,
        }));

    return {
        originalTargetId: dynamicCoreQuestion.id,
        newTargetId:
            cards.length > 0 ? cards[0].id : dynamicCoreQuestion.resultCard,
        cards,
    };
};
