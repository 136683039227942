import type {
    Answer,
    Card,
    DynamicCoreQuestion,
    Question,
    TitleCard,
} from '@dmp/qqms/types';

interface SelectNextCardIdPayload {
    answerId?: Answer['id'];
    card: Question | DynamicCoreQuestion | TitleCard;
    allCards: Card[];
}

/**
 * Determine the next Card to show based on what Answer was chosen.
 */
export function selectNextCardId({
    answerId,
    card,
    allCards,
}: SelectNextCardIdPayload): Card['id'] | undefined {
    // find next index card to fallback for undefined `resultCard`
    const idx = allCards.findIndex((c) => c.id === card.id);
    const nextIndexCard = allCards[idx + 1];
    const nextIndexCardId = nextIndexCard?.id || undefined;

    switch (card.type) {
        case 'linearQuestion':
        case 'dynamicCoreQuestion':
        case 'titleCard':
            return card.resultCard || nextIndexCardId;

        case 'forkedQuestion': {
            const answer = card.answers.find((o) => o.id === answerId);

            if (answer === undefined) {
                throw new Error(`Unable to find Answer ${answerId}.`);
            }

            return answer.resultCard || nextIndexCardId;
        }

        default:
            card satisfies never;
            throw new Error('Unsupported Card type');
    }
}
