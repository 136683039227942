import type { AnswerEvent, CardEvent } from '@dmp/qqms/types';
import { makeTraxexUrl } from './make-traxex-url';

/**
 * Generate all tracking URLs that result from a user answering a question.
 *
 * NOTE: This function does not do any checking to see if an event has
 * already been fired.  That should be handled by the consumer of this function.
 */
export const makeAnswerTrackingUrls = (answerEvent: AnswerEvent): string[] => {
    const { answers, eventContext, question, survey } = answerEvent;

    // Single Traxex events will be fired for a Question.
    const questionEvents = [
        makeTraxexUrl({
            eventContext,
            survey,
            card: question,
        }),
    ];

    /**
     * Traxex events will fire for every Answer.
     * This will be multiple events for multiple-choice
     * Questions and single vents for all other Question types.
     */
    const answersEvents = answers.reduce<Array<string | undefined>>(
        (carrier, answer) => {
            const answerEvents = [
                makeTraxexUrl({
                    eventContext,
                    answer,
                    survey,
                    card: question,
                }),
            ];

            return [...carrier, ...answerEvents];
        },
        []
    );

    return [...questionEvents, ...answersEvents].filter(isString);
};

/**
 * Generate all tracking URLs that result from a traxex-compatible card enters/exits..
 */
export const makeCardTrackingUrls = (cardEvent: CardEvent): string[] => {
    const { eventContext, card, survey } = cardEvent;

    // Single Traxex event will be fired for a Card.
    const cardEvents = [
        makeTraxexUrl({
            eventContext,
            survey,
            card,
        }),
    ];

    return cardEvents.filter(isString);
};

export const makeCardSkippedUrls = (cardEvent: CardEvent): string[] => {
    const { eventContext, card, survey } = cardEvent;
    // Single Traxex event will be fired for a Card.
    const cardEvents = [
        makeTraxexUrl({
            idSuffix: 'skipped',
            traxexLabelSuffix: 'Skipped',
            eventContext,
            survey,
            card,
        }),
    ];
    return cardEvents.filter(isString);
};

const isString = (input: unknown): input is string => typeof input === 'string';
