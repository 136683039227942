import type { TitleCard, ThankYouCard } from '@dmp/qqms/types';
import { useDispatch } from '../events/hooks';

interface TitleCardComponentProps {
    card: TitleCard | ThankYouCard;
}

export function TitleCardComponent({ card }: TitleCardComponentProps) {
    const dispatch = useDispatch();

    return (
        <>
            <div
                className="text-block"
                dangerouslySetInnerHTML={{ __html: card.body }}
            />
            {card.type === 'titleCard' ? (
                <button
                    className="title-card-button"
                    onClick={() => {
                        dispatch('titleCardInteracted', { card });
                    }}
                >
                    {card.buttonCopy}
                </button>
            ) : null}
        </>
    );
}
