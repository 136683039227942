import type {
    Answer,
    ExternalEventContext,
    FullEventContext,
    Survey,
    TraxexCompatibleCard,
} from '@dmp/qqms/types';
import {
    detectPlatform,
    getAdOrientation,
    getWindowSize,
} from '@dmp/shared/ad-utils';
import { isLinearQuestionFromCoreQuestion } from './type-guards';

interface MakeTraxexUrlInput {
    answer?: Answer;

    card: TraxexCompatibleCard;

    eventContext: ExternalEventContext;

    /**
     * The normal id we use is:
     *
     *   - `${card.id}` for a Card
     *   - `${answer.id}` for an Answer
     *
     * This suffix is appended to that id, allowing for
     * customization of the id for specific events.
     *
     * Example for card event:
     *
     * ```typescript`
     * // `id` will be `some-card-custom` instead of `some-card`
     * makeTraxexUrl({ card: { id: 'some-card', ... }, eventContext, idSuffix: 'custom' });
     * ```
     */
    idSuffix?: string;

    survey: Survey;

    /**
     * The normal, computed TraxexLabel is:
     * `${survey.traxexLabel}|${card.traxexLabel}|${answer?.traxexLabel}`
     *
     * This suffix is appended to that label, allowing for
     * customization of the TraxexLabel for specific events.
     *
     * Example for card event:
     *
     * ```typescript
     * // The Traxex label will be `SomeSurvey|SomeCard|Custom`
     * makeTraxexUrl({
     *   survey: { traxexLabel: 'SomeSurvey', ... },
     *   card: { traxexLabel: 'SomeCard', ... },
     *   eventContext,
     *   traxexLabelSuffix: 'Custom'
     * });
     * ```
     */
    traxexLabelSuffix?: string;
}

/**
 * Generate a Traxex tracking pixel.
 */
export const makeTraxexUrl = ({
    idSuffix,
    traxexLabelSuffix,
    answer,
    eventContext,
    card,
    survey,
}: MakeTraxexUrlInput): string | undefined => {
    if (!survey.traxexApi) {
        return;
    }

    const surveyTraxexLabel = isLinearQuestionFromCoreQuestion(card)
        ? 'Default'
        : survey.traxexLabel;

    const traxexLabel = [
        surveyTraxexLabel,
        card.traxexLabel,
        answer?.traxexLabel,
        traxexLabelSuffix,
    ]
        .filter(Boolean)
        .join('|');

    const id = answer
        ? `${answer.id}${idSuffix ? `-${idSuffix}` : ''}`
        : `${card.id}${idSuffix ? `-${idSuffix}` : ''}`;

    const size = getWindowSize();

    const fullEventContext: FullEventContext = {
        ...eventContext,
        id,
        app_name: 'qqms',
        app_id: survey.id,
        type: 'qq',
        qqd: traxexLabel,
        qqid: survey.qqid,
        orientation: getAdOrientation(size),
        platform: detectPlatform(),
        size: `${size.width}x${size.height}`,
    };

    const queryString = Object.keys(fullEventContext)
        .map((key) => {
            return `${key}=${encodeURIComponent(
                fullEventContext[key as keyof FullEventContext]
            )}`;
        })
        .join('&');

    return `${survey.traxexApi}/event?${queryString}`;
};
