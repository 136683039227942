import { Checkbox, FormControl, Text } from '@chakra-ui/react';
import type { Card } from '@dmp/qqms/types';
import * as React from 'react';
import { useRecoilState } from 'recoil';
import {
    cardIsSkippableState,
    cardTypeState,
} from '../../../../state/card/card-atoms';

interface CardIsSkippableProps {
    cardId: Card['id'];
}

const CardIsSkippableCom: React.FC<CardIsSkippableProps> = ({ cardId }) => {
    const [cardType] = useRecoilState(cardTypeState(cardId));
    const [cardIsSkippable, setCardIsSkippable] = useRecoilState(
        cardIsSkippableState(cardId)
    );

    if (cardType !== 'linearQuestion') {
        return null;
    }

    return (
        <FormControl id={`card-${cardId}-tracking-label`}>
            <Checkbox
                isChecked={!!cardIsSkippable}
                onChange={(e) =>
                    setCardIsSkippable(e.target.checked || undefined)
                }
                size="md"
                fontWeight="bold"
            >
                <Text fontSize="sm">Is question skippable?</Text>
            </Checkbox>
        </FormControl>
    );
};

export const CardIsSkippable = React.memo(CardIsSkippableCom);
