import { useEventListener, useSurveyState } from '@dmp/qqms-renderer';
import { differenceInMilliseconds } from 'date-fns';
import { useState } from 'react';
import {
    getAnswerTrackingUrls,
    getCardEnterTrackingUrls,
    getCardSkippedTrackingUrls,
} from '../components/common/survey-preview/get-answer-tracking-urls';

interface TrackLog {
    url: string;
    addedAt: number;
}

export const useLogTracking = () => {
    const { survey } = useSurveyState();

    // record all logs
    const [logs, setLogs] = useState<TrackLog[]>([]);

    const addUrls = (newUrls: string[]) => {
        const addedAt = Date.now();
        const newLogs = newUrls.map((url) => ({ url, addedAt }));

        setLogs((currentLogs) => {
            const recentLogs = getRecentLogs(currentLogs);
            return [...newLogs, ...recentLogs];
        });
    };

    const clearUrls = () => setLogs([]);

    useEventListener('answersSelected', ({ detail }) => {
        const urls = getAnswerTrackingUrls({ ...detail, survey });
        addUrls(urls);
    });

    useEventListener('cardEntered', ({ detail }) => {
        const urls = getCardEnterTrackingUrls({ ...detail, survey });
        addUrls(urls);
    });

    useEventListener('cardSkipped', ({ detail }) => {
        const urls = getCardSkippedTrackingUrls({ ...detail, survey });
        addUrls(urls);
    });

    const recentUrls = getRecentLogs(logs).map((log) => log.url);

    return {
        urls: recentUrls,
        logs,
        addUrls,
        clearUrls,
    };
};

/**
 * Get the most recent logs that are within the given timeframe.
 */
const getRecentLogs = (logs: TrackLog[], timeframeMs: number = 300) => {
    const recentLogs: TrackLog[] = [];

    logs.forEach((log, index) => {
        if (index === 0) {
            recentLogs.push(log);
            return;
        }

        const prevLog = recentLogs[recentLogs.length - 1];

        // within timeframe, add log into recentLogs
        const timeDiffMs = differenceInMilliseconds(
            prevLog.addedAt,
            log.addedAt
        );

        if (timeDiffMs <= timeframeMs) {
            recentLogs.push(log);
            return;
        }
    });

    return recentLogs;
};
