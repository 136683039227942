import { StackDivider, useColorMode, VStack } from '@chakra-ui/react';
import { useLogTracking } from '../../../hooks/use-log-tracking';
import { TrackingUrlList } from '../../common/tracking-url-list';
import { CardWarning } from '../card-warning';

export function CardPreviewStatus({ cardId }: { cardId: string }) {
    const { colorMode } = useColorMode();

    const { urls, clearUrls } = useLogTracking();

    return (
        <VStack
            w="100%"
            minH="80px"
            maxH="350px"
            p="4"
            spacing="4"
            fontSize="sm"
            bg={colorMode === 'dark' ? 'gray.800' : 'gray.50'}
            rounded="md"
            overflowY="auto"
            className="scrollbar"
            divider={<StackDivider />}
        >
            <CardWarning cardId={cardId} />

            {urls.length > 0 && (
                <TrackingUrlList urls={urls} onClear={clearUrls} />
            )}
        </VStack>
    );
}
