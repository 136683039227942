import type { IdMap } from '@dmp/qqms/survey-utils';
import { isQuestion, replaceCardResultIds } from '@dmp/qqms/survey-utils';
import type { Answer, Card, SurveyConfig } from '@dmp/qqms/types';
import {
    generateAnswerId,
    generateDynamicCoreQuestionId,
    generatePollResultCardId,
    generateQuestionId,
    generateThankYouCardId,
    generateTitleCardId,
} from './generate-ids';

/**
 * Duplicate a Survey, replacing all Card and Answer IDs with new ones.
 */
export const duplicateSurveyConfig = (config: SurveyConfig): SurveyConfig => {
    const idMap: IdMap = {};

    const cards = config.cards.map((card) => {
        const oldId = card.id;
        const cardWithNewId = replaceCardId(card);
        idMap[oldId] = cardWithNewId.id;

        return replaceAnswerIds(cardWithNewId);
    });

    return {
        ...config,
        cards: cards.map(replaceCardResultIds(idMap)),
    };
};

/**
 * Replace a Card with one with a new id. The new id will match the Card type.
 */
const replaceCardId = <T extends Card>(card: T): T => {
    return {
        ...card,
        id: generateNewCardId(card),
    };
};

/**
 * Generate a new id for a Card based on its type.
 */
const generateNewCardId = (card: Card): string => {
    switch (card.type) {
        case 'dynamicCoreQuestion':
            return generateDynamicCoreQuestionId();

        case 'forkedQuestion':
        case 'linearQuestion':
            return generateQuestionId();

        case 'titleCard':
            return generateTitleCardId(); // titleCard is treated as a question

        case 'thankYouCard':
            return generateThankYouCardId();

        case 'pollResultCard':
            return generatePollResultCardId();
    }
};

/**
 * Replace all answer ids on a Card with new ones match the Answer type.
 */
const replaceAnswerIds = (card: Card): Card => {
    switch (card.type) {
        case 'forkedQuestion':
            return {
                ...card,
                answers: card.answers.map(replaceAnswerId),
            };

        case 'linearQuestion':
            return {
                ...card,
                answers: card.answers.map(replaceAnswerId),
            };

        default: {
            if (isQuestion(card)) {
                throw new Error(
                    'Answer ids should be replaced for every question Card'
                );
            }

            return card;
        }
    }
};

/**
 * Replace an Answer UUID with a short ID.
 */
const replaceAnswerId = <T extends Answer>(answer: T): T => {
    return {
        ...answer,
        id: generateAnswerId(answer.type),
    };
};
