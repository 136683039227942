import { VStack } from '@chakra-ui/react';
import type { Card } from '@dmp/qqms/types';
import * as React from 'react';
import type { VisualCardType } from '../../../utils/getVisualCardType';
import { CardAsDropdown } from './card-as-dropdown';
import { CardAsDynamicCoreQuestion } from './card-as-dynamic-core-question';
import { CardAsMultiSelect } from './card-as-multi-select';
import { CardAsPollResult } from './card-as-poll-result';
import { CardAsRadio } from './card-as-radio';
import { CardAsThankYou } from './card-as-thankyou';
import { CardAsTitle } from './card-as-title';

interface CardFormsProps {
    cardId: Card['id'];
    visualCardType: VisualCardType;
}

const componentMap: Record<
    VisualCardType,
    React.ComponentType<{ cardId: string }>
> = {
    dynamicCoreQuestion: CardAsDynamicCoreQuestion,
    'multi-select': CardAsMultiSelect,
    radio: CardAsRadio,
    select: CardAsDropdown,
    titleCard: CardAsTitle,
    thankYouCard: CardAsThankYou,
    pollResultCard: CardAsPollResult,
} as const;

const CardFormsCom: React.FC<CardFormsProps> = ({ cardId, visualCardType }) => {
    const Component = componentMap[visualCardType];

    return (
        <VStack align="stretch" minW="480px" maxW="1500px" spacing="10" p="10">
            <Component cardId={cardId} />
        </VStack>
    );
};

export const CardForms = React.memo(CardFormsCom);
export default CardForms;
